// extracted by mini-css-extract-plugin
export var accImg = "Engineering-module--accImg--6b4af";
export var approachImg = "Engineering-module--approachImg--a5062";
export var btn_white1_border = "Engineering-module--btn_white1_border--c77c3";
export var description = "Engineering-module--description--0972c";
export var engineeringSQA = "Engineering-module--engineeringSQA--d798c";
export var head = "Engineering-module--head--fa926";
export var heading = "Engineering-module--heading--57466";
export var keyBtn = "Engineering-module--keyBtn--5c073";
export var nextGenRight = "Engineering-module--nextGenRight--31992";
export var quesColor = "Engineering-module--quesColor--6601d";
export var quesColorSelected = "Engineering-module--quesColorSelected--55014";
export var someColor = "Engineering-module--someColor--9de39";
export var textColor = "Engineering-module--textColor--bcb8c";