import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"
import FaqForm from "../common/hireDeveloperForm"
import bannerTick from "../../images/hire-typescript/typeBannerTick.svg"

const Banner = ({ strapiData, pageName }) => {
  return (
    <div id="hiremobileapp" className={styles.hireAngularTopBanner}>
      <Container>
        <Row className="align-items-center">
          <Col xl={7} md={12} lg={6}>
            <div className={styles.heading}>
              <h1
                className={styles.bannerHeading}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <p
                className={styles.bannerSubtitle2}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle2,
                }}
              />
              <p
                className={styles.bannerSubtitle}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
            </div>
            <div className={styles.cards}>
              {strapiData?.cards &&
                strapiData?.cards?.map((item, index) => (
                  <div className={styles.talent} key={index}>
                    <img
                      className={styles.tick}
                      src={bannerTick}
                      alt={item?.title}
                    />
                    <div className={styles.text}>
                      <p>{item?.title}</p>
                    </div>
                  </div>
                ))}
            </div>
          </Col>
          <Col xl={5} md={12} lg={6} className={`  ${styles.form}`}>
            <FaqForm pageName={pageName} mobileApp={true} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Banner
