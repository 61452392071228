// extracted by mini-css-extract-plugin
export var AngularDevelopersWeb = "HireDevelopers-module--AngularDevelopersWeb--84e41";
export var allSkills = "HireDevelopers-module--allSkills--daccc";
export var bottomRating = "HireDevelopers-module--bottomRating--583a4";
export var btnShowMore = "HireDevelopers-module--btnShowMore--666c5";
export var card = "HireDevelopers-module--card--91ed8";
export var cardDesc = "HireDevelopers-module--cardDesc--45b8f";
export var cardWrapper = "HireDevelopers-module--cardWrapper--c1252";
export var description = "HireDevelopers-module--description--d338f";
export var devContent = "HireDevelopers-module--devContent--f9467";
export var developerImg = "HireDevelopers-module--developerImg--20571";
export var developersData = "HireDevelopers-module--developersData--18cf6";
export var experience = "HireDevelopers-module--experience--2b607";
export var expert = "HireDevelopers-module--expert--59738";
export var heading = "HireDevelopers-module--heading--b1ba9";
export var mernIcon = "HireDevelopers-module--mernIcon--6c3bf";
export var review = "HireDevelopers-module--review--41324";
export var skill = "HireDevelopers-module--skill--6207b";
export var skills = "HireDevelopers-module--skills--bf5be";
export var typeBtn = "HireDevelopers-module--typeBtn--1e899";
export var typedevBtn = "HireDevelopers-module--typedevBtn--8383d";
export var whitePlus = "HireDevelopers-module--whitePlus--1e267";