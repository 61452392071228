// extracted by mini-css-extract-plugin
export var SliderWidth = "Projects-module--SliderWidth--37a82";
export var arrowImg = "Projects-module--arrowImg--c9e10";
export var bottomContent = "Projects-module--bottomContent--605e5";
export var bottomHead = "Projects-module--bottomHead--61356";
export var bottomPort = "Projects-module--bottomPort--59004";
export var bottomPort2 = "Projects-module--bottomPort2--77eb3";
export var bottomTags = "Projects-module--bottomTags--57fff";
export var bottomhead1 = "Projects-module--bottomhead1--7f21d";
export var bottomtag1 = "Projects-module--bottomtag1--edd93";
export var bottomtag2 = "Projects-module--bottomtag2--76c4d";
export var bottomtag3 = "Projects-module--bottomtag3--df05c";
export var bottomtag3Hover = "Projects-module--bottomtag3Hover--0917c";
export var cardWrapper = "Projects-module--cardWrapper--11367";
export var cards = "Projects-module--cards--ba693";
export var description = "Projects-module--description--e1e16";
export var heading = "Projects-module--heading--c8dd0";
export var headingPremium = "Projects-module--headingPremium--254a8";
export var mainWorkProjectsWebServices = "Projects-module--mainWorkProjectsWebServices--9b409";
export var portfolioArrowIcon = "Projects-module--portfolioArrowIcon--2f6cf";
export var portfolioArrowIconCover = "Projects-module--portfolioArrowIconCover--1568e";
export var portfolioArrowRightIconCover = "Projects-module--portfolioArrowRightIconCover--88dcb";
export var portfolioLinkImage = "Projects-module--portfolioLinkImage--b9620";
export var premiumImg = "Projects-module--premiumImg--7e795";
export var project2 = "Projects-module--project2--94878";
export var projectsAll = "Projects-module--projectsAll--b52f4";
export var providingImg = "Projects-module--providingImg--d5886";
export var reactJsIconContainer = "Projects-module--reactJsIconContainer--975db";
export var reactJsIconContainerLeft = "Projects-module--reactJsIconContainerLeft--63908";
export var teamButton = "Projects-module--teamButton--e53b5";
export var trailBg = "Projects-module--trailBg--a4817";