// extracted by mini-css-extract-plugin
export var SliderWidth = "Trust-module--SliderWidth--d7c80";
export var arrowImg = "Trust-module--arrowImg--8693c";
export var card = "Trust-module--card--43575";
export var cardImg = "Trust-module--cardImg--2db00";
export var cardMian = "Trust-module--cardMian--fc780";
export var cardWrapper = "Trust-module--cardWrapper--bc3f1";
export var cards = "Trust-module--cards--8f2d6";
export var cmsWebIconCOntainer = "Trust-module--cmsWebIconCOntainer--5e7bb";
export var cmsWebIconCOntainerLeft = "Trust-module--cmsWebIconCOntainerLeft--c650c";
export var description = "Trust-module--description--97c62";
export var heading = "Trust-module--heading--c75ec";
export var headingPremium = "Trust-module--headingPremium--f1ce8";
export var mainWorkFeaturesWebCms = "Trust-module--mainWorkFeaturesWebCms--3d32a";
export var portfolioArrowIcon = "Trust-module--portfolioArrowIcon--9f460";
export var portfolioArrowIconCover = "Trust-module--portfolioArrowIconCover--a2813";
export var portfolioArrowRightIconCover = "Trust-module--portfolioArrowRightIconCover--efe0d";
export var premiumImg = "Trust-module--premiumImg--ffbd8";
export var providingImg = "Trust-module--providingImg--160be";
export var teamButton = "Trust-module--teamButton--790a9";
export var trailBg = "Trust-module--trailBg--84d0b";