import React from "react"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"
import * as styles from "./Opportunity.module.scss"

const Opportunity = ({ strapiData, cmsopportunity, webOpportunity }) => {
  return (
    <div
      className={`${styles.subserviceOpportunityWeb} ${
        cmsopportunity ? styles.cmsOpportunityBanner : ""
      } ${webOpportunity ? styles.webAppOpportunity : ""}`}
    >
      <Container className={styles.con}>
        <div
          className={`${styles.ReadyOpportunity} ${
            webOpportunity ? styles.readyWebOpportunity : ""
          }`}
        >
          <h2
            className={`${styles.heading} ${
              cmsopportunity ? styles.cmsHeading : ""
            } `}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div className={`${styles.bannerBtn}`}>
            {strapiData?.buttons[0] && (
              <Link
                to={strapiData?.buttons[0]?.url}
                className={styles.btn_white2_border_Golang}
              >
                {strapiData?.buttons[0]?.title}
              </Link>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Opportunity
