import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./AngularTechStacks.scss"
import * as styles from "./AngularTechStacks.module.scss"

const data = [
  {
    title: "Programming Languages",
    tech: [
      {
        name: "Objective-C",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/objective_c_8184a02b6e.svg",
      },
      {
        name: "Swift",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/swift_35e3c6facd.svg",
      },
      {
        name: "C#",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/c_sharp_608e441947.svg",
      },
      {
        name: "Java",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/java_890cd61bee.svg",
      },
      {
        name: "Kotlin",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/kotlin_e7a5ce1f82.svg",
      },
      {
        name: "React Native",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_native_dd0e31fd65.svg",
      },
      {
        name: "PHP",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/php_bc4801e742.svg",
      },
      {
        name: "Python",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/python_3e13736a8c.svg",
      },
      {
        name: "Laravel",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/laravel_b75b3de8d9.svg",
      },
      {
        name: "Flutter",
        icon: "https://invozone-backend.s3.amazonaws.com/graphql_64900f0cb9.svg",
      },
      {
        name: "Ionic",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ionic_96aaf85c14.svg",
      },
    ],
  },
  {
    title: "Mobile App Development Tools",
    tech: [
      {
        name: "Android Studio",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/android_studio_3d39dff716.svg",
      },
      {
        name: "Android SDK",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/android_sdk_161aee8dcb.svg",
      },
      {
        name: "Xcode",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/x_code_b41619f73d.svg",
      },
    ],
  },
  {
    title: "Databases",
    tech: [
      {
        name: "Mongo DB",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mongodb_7a07730453.svg",
      },
      {
        name: "SQLite",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sqlite_161aa9f09d.svg",
      },
      {
        name: "PostgreSQL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/postgresql_35e16ea262.svg",
      },
      {
        name: "MySQL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mysql_4bb3594541.svg",
      },
    ],
  },
  {
    title: "JavaScript Domains",
    tech: [
      {
        name: "Node.js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/node_js_5a747e51da.svg",
      },
      {
        name: "Angular.js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/angular_js_945d33e0fc.svg",
      },
      {
        name: "React.js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_js_58286b2d76.svg",
      },
      {
        name: "Java",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/java_890cd61bee.svg",
      },
    ],
  },
  {
    title: "Cloud Services",
    tech: [
      {
        name: "Google Cloud",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/google_cloud_567d5c2f71.svg",
      },
      {
        name: "DevOps",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/dev_ops_3fd11f2b4f.svg",
      },
    ],
  },
]

const AngularTechStacks = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className="WebTabStacksAngularHire">
      <div className={styles.TechStacksWebHireAngular}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={styles.cardsContentAngular}>
              <div>
                <Nav variant="pills" className={`WebTabStacksAngularHire`}>
                  {data &&
                    data?.map((e, i) => (
                      <Nav.Item key={i} className="cards">
                        <div
                          eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          <div className={styles.tabData}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <Tab.Content className="contentBlockAngularWeb">
                {data &&
                  data?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default AngularTechStacks
