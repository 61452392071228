// extracted by mini-css-extract-plugin
export var ReadyOpportunity = "Opportunity-module--ReadyOpportunity--ec7be";
export var bannerBtn = "Opportunity-module--bannerBtn--b0ee9";
export var btn_white2_border_Golang = "Opportunity-module--btn_white2_border_Golang--864c5";
export var cmsHeading = "Opportunity-module--cmsHeading--25099";
export var cmsOpportunityBanner = "Opportunity-module--cmsOpportunityBanner--9a999";
export var con = "Opportunity-module--con--3a842";
export var heading = "Opportunity-module--heading--dac77";
export var noCodeHeading = "Opportunity-module--noCodeHeading--c64b2";
export var nocodeopportunity = "Opportunity-module--nocodeopportunity--c3e83";
export var readyWebOpportunity = "Opportunity-module--readyWebOpportunity--75a0d";
export var subserviceOpportunityWeb = "Opportunity-module--subserviceOpportunityWeb--c8d95";
export var webAppOpportunity = "Opportunity-module--webAppOpportunity--2e565";