// extracted by mini-css-extract-plugin
export var bottomContent = "ProjectsMobile-module--bottomContent--e06f6";
export var bottomHead = "ProjectsMobile-module--bottomHead--7d6ee";
export var bottomPort = "ProjectsMobile-module--bottomPort--d8e9c";
export var bottomPort2 = "ProjectsMobile-module--bottomPort2--c922c";
export var bottomTags = "ProjectsMobile-module--bottomTags--54c06";
export var bottomhead1 = "ProjectsMobile-module--bottomhead1--216bb";
export var bottomtag1 = "ProjectsMobile-module--bottomtag1--ced19";
export var bottomtag2 = "ProjectsMobile-module--bottomtag2--803bc";
export var bottomtag3 = "ProjectsMobile-module--bottomtag3--aea34";
export var bottomtag3Hover = "ProjectsMobile-module--bottomtag3Hover--c193f";
export var cardWrapper = "ProjectsMobile-module--cardWrapper--3d6b9";
export var caseStudiesBtnPortfolio = "ProjectsMobile-module--caseStudiesBtnPortfolio--f79ca";
export var description = "ProjectsMobile-module--description--cee57";
export var firstMobileImagaPort = "ProjectsMobile-module--firstMobileImagaPort--0da2d";
export var firstPortBottom = "ProjectsMobile-module--firstPortBottom--6a0b0";
export var firstPortFolio = "ProjectsMobile-module--firstPortFolio--d0a9a";
export var head = "ProjectsMobile-module--head--ac45d";
export var heading = "ProjectsMobile-module--heading--a438c";
export var hoverFirstPortImg = "ProjectsMobile-module--hoverFirstPortImg--efdfa";
export var normalrFirstPortImg = "ProjectsMobile-module--normalrFirstPortImg--e105d";
export var portImage = "ProjectsMobile-module--portImage--09dd3";
export var portfolioLinkImage = "ProjectsMobile-module--portfolioLinkImage--897a1";
export var project2 = "ProjectsMobile-module--project2--4b483";
export var projectsAll = "ProjectsMobile-module--projectsAll--28113";
export var projectsMobileWeb = "ProjectsMobile-module--projectsMobileWeb--b900f";
export var tag1 = "ProjectsMobile-module--tag1--e9d3c";
export var tag2 = "ProjectsMobile-module--tag2--4645d";
export var tag3 = "ProjectsMobile-module--tag3--b6ace";
export var tags = "ProjectsMobile-module--tags--20cc0";