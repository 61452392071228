// extracted by mini-css-extract-plugin
export var TechStacksWebHireAngular = "AngularTechStacks-module--TechStacksWebHireAngular--76164";
export var cardBg = "AngularTechStacks-module--cardBg--32d88";
export var cards = "AngularTechStacks-module--cards--68519";
export var cardsContentAngular = "AngularTechStacks-module--cardsContentAngular--b1293";
export var description = "AngularTechStacks-module--description--45d87";
export var heading = "AngularTechStacks-module--heading--08d9d";
export var iconssCard = "AngularTechStacks-module--iconssCard--787bb";
export var nav = "AngularTechStacks-module--nav--edd50";
export var navItem = "AngularTechStacks-module--nav-item--b2be3";
export var navbarBlock = "AngularTechStacks-module--navbarBlock--f6c0b";
export var tabData = "AngularTechStacks-module--tabData--55a24";
export var tech = "AngularTechStacks-module--tech--15d8d";
export var techIcon = "AngularTechStacks-module--techIcon--edb10";
export var techImg = "AngularTechStacks-module--techImg--90050";
export var technologyIcon = "AngularTechStacks-module--technologyIcon--42f02";