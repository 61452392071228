// extracted by mini-css-extract-plugin
export var AngularIndusTechHireWeb = "AngularTechStacksMobile-module--AngularIndusTechHireWeb--70e16";
export var cardBg = "AngularTechStacksMobile-module--cardBg--cf37e";
export var cards = "AngularTechStacksMobile-module--cards--cec8c";
export var cardsContent = "AngularTechStacksMobile-module--cardsContent--716a3";
export var description = "AngularTechStacksMobile-module--description--da76a";
export var heading = "AngularTechStacksMobile-module--heading--735b2";
export var iconssCard = "AngularTechStacksMobile-module--iconssCard--d2df4";
export var nav = "AngularTechStacksMobile-module--nav--1502e";
export var navItem = "AngularTechStacksMobile-module--nav-item--3bf41";
export var navbarBlock = "AngularTechStacksMobile-module--navbarBlock--49aff";
export var tabData = "AngularTechStacksMobile-module--tabData--01845";
export var tech = "AngularTechStacksMobile-module--tech--848b8";
export var techIcon = "AngularTechStacksMobile-module--techIcon--25caa";
export var techImg = "AngularTechStacksMobile-module--techImg--e5612";
export var technologyIcon = "AngularTechStacksMobile-module--technologyIcon--bc2da";