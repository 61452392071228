// extracted by mini-css-extract-plugin
export var bannerBtnHowAngular = "HowHireAngular-module--bannerBtnHowAngular--68b56";
export var card = "HowHireAngular-module--card--ebe77";
export var cardAllData = "HowHireAngular-module--cardAllData--0500a";
export var cardContent = "HowHireAngular-module--cardContent--b1277";
export var cardWrapper = "HowHireAngular-module--cardWrapper--22716";
export var description = "HowHireAngular-module--description--ac192";
export var getBtnAngular = "HowHireAngular-module--getBtnAngular--c85b5";
export var headerContent = "HowHireAngular-module--headerContent--3ed58";
export var heading = "HowHireAngular-module--heading--e530a";
export var howHireAngular = "HowHireAngular-module--howHireAngular--b2eed";
export var lottieImage = "HowHireAngular-module--lottieImage--689c4";
export var steps = "HowHireAngular-module--steps--f432f";