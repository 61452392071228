// extracted by mini-css-extract-plugin
export var bannerHeading = "Banner-module--bannerHeading--d2d90";
export var bannerSubtitle = "Banner-module--bannerSubtitle--5838e";
export var bannerSubtitle2 = "Banner-module--bannerSubtitle2--994e1";
export var cards = "Banner-module--cards--8f917";
export var form = "Banner-module--form--c41cb";
export var heading = "Banner-module--heading--85430";
export var hireAngularTopBanner = "Banner-module--hireAngularTopBanner--0c9d3";
export var talent = "Banner-module--talent--97409";
export var text = "Banner-module--text--6b2e7";
export var tick = "Banner-module--tick--b8f0a";