// extracted by mini-css-extract-plugin
export var ServicesWhyAngular = "WhyHireAngular-module--ServicesWhyAngular--b44da";
export var card = "WhyHireAngular-module--card--7ed31";
export var cardWrapper = "WhyHireAngular-module--cardWrapper--04667";
export var description = "WhyHireAngular-module--description--eec91";
export var heading = "WhyHireAngular-module--heading--0aabf";
export var loader = "WhyHireAngular-module--loader--3721d";
export var lodaMoreBtn = "WhyHireAngular-module--lodaMoreBtn--55591";
export var lodalessBtn = "WhyHireAngular-module--lodalessBtn--44479";
export var showlessdiv = "WhyHireAngular-module--showlessdiv--372f7";
export var spin = "WhyHireAngular-module--spin--762b0";
export var trailBg = "WhyHireAngular-module--trailBg--2ff75";